import Button from '@components/atoms/button'
import BookFreeClassBtn from '@components/common/book-free-class-btn'
import StudentProjectCard from '@components/common/project-card-v2'
import useDetectCountry from '@hooks/useDetectCountry'
import { HOMEPAGE_STUDENT_PROJECTS } from '@lib/data/home-student-projects'
import { Trans } from '@lingui/macro'
import Link from 'next/link'

const IMAGE_PATH = '/images/home/student-projects/v1/'

type Props = {
  localeCountryCode?: string
}

/** i18n support added */
const StudentProjects = ({ localeCountryCode }: Props) => {
  const { countryCode } = useDetectCountry()
  const projects =
    HOMEPAGE_STUDENT_PROJECTS[localeCountryCode || countryCode] ||
    HOMEPAGE_STUDENT_PROJECTS['default']

  return (
    <div className="relative px-5 py-16 bg-orange-100">
      <div className="container mx-auto text-grey-900">
        <h2 className="text-3xl lg:text-4xl leading-tight text-center font-600 mb-10">
          <Trans id="homepage.studentProjects.heading">
            See amazing projects{' '}
            <span className="yellow-underline">created by our students</span>
          </Trans>
        </h2>
        <div className="relative grid grid-cols-1 my-4 lg:justify-center gap-y-5 md:gap-6 lg:gap-8 md:grid-cols-2 lg:grid-cols-3">
          {projects.map((project) => (
            <StudentProjectCard
              key={project.projectLink}
              imagePath={IMAGE_PATH}
              {...project}
            />
          ))}
        </div>
        <div className="flex flex-col justify-center mt-10 gap-y-4 lg:mx-0 lg:flex-row lg:gap-x-1">
          <Link href="/student-coding-projects/" prefetch={false}>
            <a>
              <Button variant="outline" className="w-full" size="xl">
                <Trans>See more student projects</Trans>
              </Button>
            </a>
          </Link>
          <BookFreeClassBtn size="xl" />
        </div>
      </div>
    </div>
  )
}

export default StudentProjects
