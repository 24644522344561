import { handleRedirectionWithSignupSource, scrollToTop } from '@common/utils'
import Button, { ButtonProps } from '@components/atoms/button'
import MsgTrans from '@components/common/i18n/message-trans'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import { useRouter } from 'next/router'

type Props = ButtonProps & {
  ctaText?: string | LinguiJSMessageDescriptor
  shouldScrollToTop?: boolean
  className?: string
  fullWidth?: boolean
  query?: string
}

const BookFreeClassButton = ({
  ctaText = t`Try a free lesson`,
  shouldScrollToTop = false,
  className = '',
  fullWidth = false,
  query,
  ...props
}: Props) => {
  const router = useRouter()

  const handleClick = () =>
    shouldScrollToTop
      ? scrollToTop()
      : query
      ? handleRedirectionWithSignupSource(router.asPath, `/register/${query}`)
      : handleRedirectionWithSignupSource(router.asPath, `/register/`)

  return (
    <Button
      {...props}
      onClick={handleClick}
      className={classNames(
        'inline-block w-full',
        {
          'lg:w-auto': !fullWidth,
        },
        className
      )}
    >
      <MsgTrans msg={ctaText} />
    </Button>
  )
}

export default BookFreeClassButton
