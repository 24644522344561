import { useState } from 'react'
import { Arrow, useHover, useLayer } from 'react-laag'
import { PlacementType } from 'react-laag/dist/PlacementType'

type TooltipProps = {
  children: React.ReactNode
  content: React.ReactNode
  showOnHover?: boolean
  placement?: PlacementType
  disabled?: boolean
  className?: string
  delayEnter?: number
  delayLeave?: number
  enableCopy?: boolean
}

const Tooltip = ({
  children,
  content,
  showOnHover,
  placement = 'right-center',
  disabled = false,
  className,
  delayEnter = 50,
  delayLeave = 50,
  enableCopy,
}: TooltipProps) => {
  // state used for click event
  const [isOpen, setIsOpen] = useState(false)
  // state used for hover event
  const [isOver, hoverProps] = useHover({ delayEnter, delayLeave })

  const isTooltipVisible = disabled ? false : showOnHover ? isOver : isOpen

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isTooltipVisible,
    placement: placement,
    auto: true,
    triggerOffset: 15,

    onOutsideClick: () => setIsOpen(false),
  })
  const [copySuccess, setCopySuccess] = useState(false)

  const copyToClipboard = (text) => {
    if (!enableCopy) return
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopySuccess(true)
        setTimeout(() => setCopySuccess(false), 2000) // Reset after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
      })
  }

  return (
    <>
      <div
        {...triggerProps}
        {...hoverProps}
        onClick={() => copyToClipboard(content.toString())}
        className={className}
      >
        {children}
      </div>
      {isTooltipVisible &&
        renderLayer(
          <div
            className="relative z-50 px-3 py-2 text-sm bg-white border rounded-md shadow-lg select-none tooltip text-grey border-grey-500 max-w-4/5"
            {...layerProps}
            {...hoverProps}
            onClick={() => copyToClipboard(content.toString())}
          >
            {copySuccess ? <span>Copied!</span> : content}
            <Arrow
              {...arrowProps}
              roundness={1}
              borderWidth={1}
              borderColor="#D8DDE6"
            />
          </div>
        )}
    </>
  )
}

export default Tooltip
