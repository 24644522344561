import { defineMessage } from '@lingui/macro'
import { i18n } from '@lingui/core'
import { LinguiJSMessageDescriptor } from '@lib/types/common'

export type HomepageStudentProjectType = {
  studentName: string | LinguiJSMessageDescriptor
  studentImage?: string
  grade: number | string
  projectName: LinguiJSMessageDescriptor
  projectImage: string
  projectCategory: LinguiJSMessageDescriptor
  projectDescription: LinguiJSMessageDescriptor
  projectLink: string
  isAndroidApp?: boolean
}

export const HOMEPAGE_STUDENT_PROJECTS: Record<
  string,
  HomepageStudentProjectType[]
> = {
  default: [
    {
      studentName: defineMessage({ id: '[Name]Kavin', message: `Kavin` }),
      studentImage: 'Kavin',
      grade: i18n.number(10),
      projectName: defineMessage({ message: `Ticket booking website` }),
      projectImage: 'Ticket booking website',
      projectDescription: defineMessage({
        message: `This website helps you book tickets for the T20 world cup.`,
      }),
      projectCategory: defineMessage({ message: `Website development` }),
      projectLink:
        'https://replit.com/@KavinK7/pseudoclasses-css-hw-project#index.html',
    },
    {
      studentName: defineMessage({
        id: '[Name]Syeda Hiba Sikandar',
        message: `Syeda Hiba Sikandar`,
      }),
      studentImage: 'Syeda Hiba Sikandar',
      grade: i18n.number(3),
      projectName: defineMessage({ message: `Dance party` }),
      projectImage: 'Dance party',
      projectCategory: defineMessage({ message: `Animation` }),
      projectDescription: defineMessage({
        message: `Dance around with the characters in this dance party.`,
      }),
      projectLink:
        'https://studio.code.org/projects/dance/QNCLZ8zXoLHRWDh5b2A0-gyi2Aw_MQJ0oFubWSZCHxQ',
    },
    {
      studentName: defineMessage({
        id: '[Name]Megha Rae',
        message: `Megha Rae`,
      }),
      studentImage: 'Megha Rae',
      grade: i18n.number(6),
      projectName: defineMessage({ message: `Knowing About Animals` }),
      projectImage: 'Knowing About Animals',
      projectCategory: defineMessage({ message: `Scratch` }),
      projectDescription: defineMessage({
        message: `This is a project which lets you know about different animals.`,
      }),
      projectLink: 'https://scratch.mit.edu/projects/694562001',
    },
    {
      studentName: defineMessage({
        id: '[Name]Samhith Gurajapu',
        message: `Samhith Gurajapu`,
      }),
      studentImage: 'Samhith Gurajapu',
      grade: i18n.number(2),
      projectName: defineMessage({ message: `Solar system` }),
      projectImage: 'Solar system',
      projectCategory: defineMessage({ message: `Animation` }),
      projectDescription: defineMessage({
        message: `This project teaches you about different planets of solar system.`,
      }),
      projectLink: 'https://scratch.mit.edu/projects/718764891',
    },
    {
      studentName: defineMessage({
        id: '[Name]Abdullah Saad',
        message: `Abdullah Saad`,
      }),
      studentImage: 'Abdullah Saad',
      grade: i18n.number(7),
      projectName: defineMessage({
        message: `Life cycle of a butterfly`,
      }),
      projectImage: 'life-cycle-butterfly',
      projectCategory: defineMessage({ message: `Animation` }),
      projectDescription: defineMessage({
        message: `This project shows you different life stages of a butterfly.`,
      }),
      projectLink:
        'https://studio.code.org/projects/gamelab/6tXamCTkoD8QIzMmkqtjn5WrYXxQx63sJ6ylHv-cFn0',
    },
    {
      studentName: defineMessage({ id: '[Name]Ankit V', message: `Ankit V` }),
      studentImage: 'Ankit V',
      grade: i18n.number(3),
      projectName: defineMessage({ message: `Rocket Launch` }),
      projectImage: 'Rocket Launch',
      projectCategory: defineMessage({ message: `Python` }),
      projectDescription: defineMessage({
        message: `This project shows animated stages of rocket launch to International Space Station.`,
      }),
      projectLink:
        'https://studio.code.org/projects/spritelab/w8z_G53kG9rnNLrVtgKUqmImSDVHboPlvqw-7fxi6bk',
    },
  ],
  PK: [
    {
      studentName: defineMessage({ id: '[Name]Wania', message: `Wania` }),
      studentImage: 'Wania',
      grade: i18n.number(1),
      projectName: defineMessage({ message: `Color Combination` }),
      projectImage: 'Color Combination',
      projectCategory: defineMessage({ message: `Animation` }),
      projectDescription: defineMessage({
        message: `This project describes how different color mix together to make another color.`,
      }),
      projectLink:
        'https://studio.code.org/projects/spritelab/62E1IpyX_8TgHhKzU_ciMdtKIvYFvyoi4V9um0AQpBM',
    },
    {
      studentName: defineMessage({
        id: '[Name]Ali Imran',
        message: `Ali Imran`,
      }),
      studentImage: 'Ali Imran',
      grade: i18n.number(8),
      projectName: defineMessage({ message: `M_A_N_N_E_R_S` }),
      projectImage: 'M_A_N_N_E_R_S',
      projectCategory: defineMessage({ message: `Scratch` }),
      projectDescription: defineMessage({
        message: `In this project you can learn about different manners which can help you become a good boy/girl.`,
      }),
      projectLink: 'https://scratch.mit.edu/projects/696947228',
    },
    {
      studentName: defineMessage({
        id: '[Name]Umer Furqan',
        message: `Umer Furqan`,
      }),
      studentImage: 'Umer Furqan',
      grade: i18n.number(4),
      projectName: defineMessage({ message: `Zombieland` }),
      projectImage: 'Zombieland-2',
      projectCategory: defineMessage({ message: `Scratch` }),
      projectDescription: defineMessage({
        message: `Welcome to Zombieland. Shoot the zombies to save yourself.`,
      }),
      projectLink: 'https://scratch.mit.edu/projects/720516748',
    },
    {
      studentName: defineMessage({
        id: '[Name]Syeda Hiba Sikandar',
        message: `Syeda Hiba Sikandar`,
      }),
      studentImage: 'Syeda Hiba Sikandar',
      grade: i18n.number(3),
      projectName: defineMessage({ message: `Dance party` }),
      projectImage: 'Dance party',
      projectCategory: defineMessage({ message: `Animation` }),
      projectDescription: defineMessage({
        message: `Dance around with the characters in this dance party.`,
      }),
      projectLink:
        'https://studio.code.org/projects/dance/QNCLZ8zXoLHRWDh5b2A0-gyi2Aw_MQJ0oFubWSZCHxQ',
    },
    {
      studentName: defineMessage({
        id: '[Name]Zohra Manzoor',
        message: `Zohra Manzoor`,
      }),
      studentImage: 'Zohra Manzoor',
      grade: i18n.number(7),
      projectName: defineMessage({ message: `Car game` }),
      projectImage: 'Car game-2',
      projectCategory: defineMessage({ message: `Scratch` }),
      projectDescription: defineMessage({
        message: `Drive around the car on the highway. Beware of the obstacles.`,
      }),
      projectLink: 'https://scratch.mit.edu/projects/703947964',
    },
    {
      studentName: defineMessage({
        id: '[Name]Ahmad Taha',
        message: `Ahmad Taha`,
      }),
      studentImage: 'Ahmad Taha',
      grade: i18n.number(10),
      projectName: defineMessage({ message: `Random password generator` }),
      projectImage: 'Random password generator',
      projectCategory: defineMessage({ message: `Python` }),
      projectDescription: defineMessage({
        message: `Create a random passoword with this Python program.`,
      }),
      projectLink: 'https://replit.com/@AhmadTaha3/random-password-tkinter?v=1',
    },
  ],
  BD: [
    {
      studentName: defineMessage({
        id: '[Name]Hadiqah Riyadh',
        message: `Hadiqah Riyadh`,
      }),
      studentImage: 'Hadiqah Riyadh',
      grade: i18n.number(1),
      projectName: defineMessage({ message: `Solar system` }),
      projectImage: 'Solar system-2',
      projectCategory: defineMessage({ message: `Website development` }),
      projectDescription: defineMessage({
        message: `This project teaches you about different planets of solar system.`,
      }),
      projectLink: 'https://scratch.mit.edu/projects/718764891',
    },
    {
      studentName: defineMessage({
        id: '[Name]Mohammed Tashfi nawrose',
        message: `Mohammed Tashfi nawrose`,
      }),
      studentImage: 'Mohammed Tashfi nawrose',
      grade: i18n.number(5),
      projectName: defineMessage({ message: `Paint Box` }),
      projectImage: 'Paint Box',
      projectCategory: defineMessage({ message: `Scratch` }),
      projectDescription: defineMessage({
        message: `Create your own paint box in Scratch.`,
      }),
      projectLink: 'https://scratch.mit.edu/projects/729922677',
    },
    {
      studentName: defineMessage({
        id: '[Name]Abdullah Saad',
        message: `Abdullah Saad`,
      }),
      studentImage: 'Abdullah Saad',
      grade: i18n.number(7),
      projectName: defineMessage({
        message: `Life cycle of a butterfly`,
      }),
      projectImage: 'Life cycle of a butterfly',
      projectCategory: defineMessage({ message: `Animation` }),
      projectDescription: defineMessage({
        message: `This project shows you different life stages of a butterfly.`,
      }),
      projectLink:
        'https://studio.code.org/projects/gamelab/6tXamCTkoD8QIzMmkqtjn5WrYXxQx63sJ6ylHv-cFn0',
    },
    {
      studentName: defineMessage({
        id: '[Name]Mahadia Mutmainna Binte Ahmed',
        message: `Mahadia Mutmainna Binte Ahmed`,
      }),
      studentImage: 'Mahadia Mutmainna Binte Ahmed',
      grade: i18n.number(11),
      projectName: defineMessage({
        message: `Rock paper scissor`,
      }),
      projectImage: 'Rock paper scissor-2',
      projectCategory: defineMessage({ message: `Python` }),
      projectDescription: defineMessage({
        message: `A Rock paper scissor game created using Python programming.`,
      }),
      projectLink:
        'https://replit.com/@MahadiaMutmainn/RockPaperScissors-game#main.py',
    },
    {
      studentName: defineMessage({
        id: '[Name]Subah Sharika',
        message: `Subah Sharika`,
      }),
      studentImage: 'Subah Sharika',
      grade: i18n.number(9),
      projectName: defineMessage({
        message: `My hobbies`,
      }),
      projectImage: 'My hobbies',
      projectCategory: defineMessage({ message: `Website development` }),
      projectDescription: defineMessage({
        message: `This websites tells you about different types of hobbies that you can persue in your leasure time.`,
      }),
      projectLink: 'https://replit.com/@NewKid3/Homework-17Bootstrap?v=1',
    },
    {
      studentName: defineMessage({
        id: '[Name]Mayisha tasnia',
        message: `Mayisha tasnia`,
      }),
      studentImage: 'Mayisha tasnia',
      grade: i18n.number(11),
      projectName: defineMessage({
        message: `Registration form`,
      }),
      projectImage: 'Registration form',
      projectCategory: defineMessage({ message: `Python` }),
      projectDescription: defineMessage({
        message: `A registration form created using HTML and CSS.`,
      }),
      projectLink: 'https://replit.com/@NahidSultana3/Form-in-html?v=1',
    },
  ],

  US: [
    {
      studentName: defineMessage({
        id: '[Name]Coulibaly Oleg Arni Doutuan',
        message: `Coulibaly Oleg Arni Doutuan`,
      }),
      studentImage: 'Coulibaly Oleg Arni Doutuan',
      grade: i18n.number(10),
      projectName: defineMessage({ message: `Premier league` }),
      projectImage: 'Premier league',
      projectDescription: defineMessage({
        message: `Vote for your favorite premier league person with this website.`,
      }),
      projectCategory: defineMessage({ message: `Website development` }),
      projectLink: 'https://replit.com/@Ouekalo-DanielD/Project-12?v=1',
    },
    {
      studentName: defineMessage({
        id: '[Name]Samuel Bello',
        message: `Samuel Bello`,
      }),
      studentImage: 'Samuel Bello',
      grade: i18n.number(12),
      projectName: defineMessage({ message: `Catch the falling stars` }),
      projectImage: 'Catch the falling stars',
      projectDescription: defineMessage({
        message: `Catch the falling stars into the basket to get points. `,
      }),
      projectCategory: defineMessage({ message: `Scratch` }),
      projectLink: 'https://scratch.mit.edu/projects/666664564',
    },
    {
      studentName: defineMessage({
        id: '[Name]Sabeel Kalemzai',
        message: `Sabeel Kalemzai`,
      }),
      studentImage: 'Sabeel Kalemzai',
      grade: i18n.number(10),
      projectName: defineMessage({ message: `Zombieland` }),
      projectImage: 'Zombieland',
      projectDescription: defineMessage({
        message: `Welcome to Zombieland. Shoot the zombies to save yourself.`,
      }),
      projectCategory: defineMessage({ message: `Scratch` }),
      projectLink: 'https://scratch.mit.edu/projects/718035621',
    },
    {
      studentName: defineMessage({ id: '[Name]Kavin', message: `Kavin` }),
      studentImage: 'Kavin',
      grade: i18n.number(10),
      projectName: defineMessage({ message: `Ticket booking website` }),
      projectImage: 'Ticket booking website',
      projectDescription: defineMessage({
        message: `This website helps you book tickets for the T20 world cup.`,
      }),
      projectCategory: defineMessage({ message: `Website development` }),
      projectLink:
        'https://replit.com/@KavinK7/pseudoclasses-css-hw-project#index.html',
    },
    {
      studentName: defineMessage({
        id: '[Name]Ricky Huang',
        message: `Ricky Huang`,
      }),
      studentImage: 'Ricky Huang',
      grade: i18n.number(10),
      projectName: defineMessage({ message: `Car game` }),
      projectImage: 'Car game',
      projectDescription: defineMessage({
        message: `Drive around the car on the highway. Beware of the obstacles.`,
      }),
      projectCategory: defineMessage({ message: `Scratch` }),
      projectLink: 'https://scratch.mit.edu/projects/670482808',
    },
    {
      studentName: defineMessage({ id: '[Name]Denzell', message: `Denzell` }),
      studentImage: 'Denzell',
      grade: i18n.number(10),
      projectName: defineMessage({ message: `Virtual journal` }),
      projectImage: 'Virtual journal',
      projectDescription: defineMessage({
        message: `This is a journal app in which you can make your shopping list, To-Do list etc.`,
      }),
      projectCategory: defineMessage({ message: `App development` }),
      projectLink:
        'https://x.thunkable.com/projectPage/637cc674fa5179021962d5cc',
    },
  ],

  CA: [
    {
      studentName: defineMessage({
        id: '[Name]Ruben Maxwell',
        message: `Ruben Maxwell`,
      }),
      studentImage: 'Ruben Maxwell',
      grade: i18n.number(12),
      projectName: defineMessage({ message: `Rock paper scissor` }),
      projectImage: 'Rock paper scissor-2',
      projectDescription: defineMessage({
        message: `A Rock paper scissor game created using Python programming.`,
      }),
      projectCategory: defineMessage({ message: `Python` }),
      projectLink:
        'https://replit.com/@BenMax/Rock-Paper-Scissors-using-Tkinter-1?v=1',
    },
    {
      studentName: defineMessage({ id: '[Name]Yvan', message: `Yvan` }),
      studentImage: 'Yvan',
      grade: i18n.number(3),
      projectName: defineMessage({ message: `My Website` }),
      projectImage: 'My Website',
      projectDescription: defineMessage({
        message: `A detailed webste about NBA Youngboy.`,
      }),
      projectCategory: defineMessage({ message: `Website development` }),
      projectLink: 'https://replit.com/@nkagmen1/project?v=1',
    },
    {
      studentName: defineMessage({
        id: '[Name]Priyanshu Chakraborty',
        message: `Priyanshu Chakraborty`,
      }),
      studentImage: 'Priyanshu Chakraborty',
      grade: i18n.number(5),
      projectName: defineMessage({ message: `Dancing to the tunes of DJ Cat` }),
      projectImage: 'Dancing to the tunes of DJ Cat',
      projectDescription: defineMessage({
        message: `Dance to the tunes of DJ Cat with this fun animated game.`,
      }),
      projectCategory: defineMessage({ message: `Scratch` }),
      projectLink: 'https://scratch.mit.edu/projects/753553491',
    },
    {
      studentName: defineMessage({
        id: '[Name]Iyla Ineson',
        message: `Iyla Ineson`,
      }),
      studentImage: 'Iyla Ineson',
      grade: i18n.number(7),
      projectName: defineMessage({ message: `Age converter App` }),
      projectImage: 'Age converter App',
      projectDescription: defineMessage({
        message: `With this app you can calculate how many hours, minutes and seconds have you spent on earth.`,
      }),
      projectCategory: defineMessage({ message: `App development` }),
      projectLink:
        'https://studio.code.org/projects/applab/eAkcnehyH7ICpN9s0cOr_yYXUZQ6K_ICvF1ZoE-_LTM',
    },
    {
      studentName: defineMessage({
        id: '[Name]Rida Sajid',
        message: `Rida Sajid`,
      }),
      studentImage: 'Rida Sajid',
      grade: i18n.number(4),
      projectName: defineMessage({ message: `Bubble count` }),
      projectImage: 'Bubble count',
      projectDescription: defineMessage({
        message: `Click on the bubbles to burst them. You get points for every bubble you burst.`,
      }),
      projectCategory: defineMessage({ message: `Game development` }),
      projectLink:
        'https://studio.code.org/projects/spritelab/FMPsa1fHT2DKr93wr5cTAFl_qeGLDx52GkG8dqvCwfU',
    },
    {
      studentName: defineMessage({ id: '[Name]Denzell', message: `Denzell` }),
      studentImage: 'Denzell',
      grade: i18n.number(10),
      projectName: defineMessage({ message: `Virtual journal` }),
      projectImage: 'Virtual journal',
      projectDescription: defineMessage({
        message: `This is a journal app in which you can make your shopping list, To-Do list etc.`,
      }),
      projectCategory: defineMessage({ message: `App development` }),
      projectLink:
        'https://x.thunkable.com/projectPage/637cc674fa5179021962d5cc',
    },
  ],

  NG: [
    {
      studentName: defineMessage({
        id: '[Name]Abubakar Yusuf',
        message: `Abubakar Yusuf`,
      }),
      studentImage: 'Abubakar Yusuf',
      grade: i18n.number(7),
      projectName: defineMessage({ message: `Catch the falling stars` }),
      projectImage: 'Catch the falling stars-2',
      projectDescription: defineMessage({
        message: `Catch the falling stars into the basket to get points.`,
      }),
      projectCategory: defineMessage({ message: `Scratch` }),
      projectLink: 'https://scratch.mit.edu/projects/704538607',
    },
    {
      studentName: defineMessage({
        id: '[Name]Kenechi Valentine Kenechi',
        message: `Kenechi Valentine Kenechi`,
      }),
      studentImage: 'Kenechi Valentine Kenechi',
      grade: i18n.number(8),
      projectName: defineMessage({ message: `Collect the trophy’s` }),
      projectImage: 'Collect the trophy’s',
      projectDescription: defineMessage({
        message: `Collect as many trophies as posiible to get more points.`,
      }),
      projectCategory: defineMessage({ message: `Scratch` }),
      projectLink: 'https://scratch.mit.edu/projects/710926627/',
    },
    {
      studentName: defineMessage({
        id: '[Name]Oluwadurotimi Ayinla',
        message: `Oluwadurotimi Ayinla`,
      }),
      studentImage: 'Oluwadurotimi Ayinla',
      grade: i18n.number(12),
      projectName: defineMessage({ message: `Summer school` }),
      projectImage: 'Summer school',
      projectDescription: defineMessage({
        message: `This website helps you get registered for the summer school.`,
      }),
      projectCategory: defineMessage({ message: `Website development` }),
      projectLink:
        'https://replit.com/@OluwadamilolaAy/M1L6Elaborate-your-registration-form-entries-1?v=1',
    },
    {
      studentName: defineMessage({
        id: '[Name]Boluwarin Treasure Stephen',
        message: `Boluwarin Treasure Stephen`,
      }),
      studentImage: 'Boluwarin Treasure Stephen',
      grade: i18n.number(8),
      projectName: defineMessage({ message: `Oggy the frog` }),
      projectImage: 'Oggy the frog',
      projectDescription: defineMessage({
        message: `Make the frog eat the bug to make it bigger.`,
      }),
      projectCategory: defineMessage({ message: `Scratch` }),
      projectLink: 'https://scratch.mit.edu/projects/723423821',
    },
    {
      studentName: defineMessage({
        id: '[Name]Nicolas oyalowo',
        message: `Nicolas oyalowo`,
      }),
      studentImage: 'Nicolas oyalowo',
      grade: i18n.number(5),
      projectName: defineMessage({ message: `Table generator` }),
      projectImage: 'Table generator',
      projectDescription: defineMessage({
        message: `You can generate any number table with this application.`,
      }),
      projectCategory: defineMessage({ message: `App development` }),
      projectLink:
        'https://studio.code.org/projects/applab/P-BsF15tQXazey9fTWkmLtyXbxku7j5r9QCP9p7PhEI',
    },
    {
      studentName: defineMessage({
        id: '[Name]Kumator Angera Tion',
        message: `Kumator Angera Tion`,
      }),
      studentImage: 'Kumator Angera Tion',
      grade: i18n.number(1),
      projectName: defineMessage({ message: `Ninja clash` }),
      projectImage: 'Ninja clash',
      projectDescription: defineMessage({
        message: `Make the Ninja kill the enemies. Get points for every enemy that you kill.`,
      }),
      projectCategory: defineMessage({ message: `Game development` }),
      projectLink:
        'https://studio.code.org/projects/playlab/TXP-6ovDU1LwaoOKsCwLYCa3VLnG30umQh7t3UW2Jj8',
    },
  ],
  UG: [
    {
      studentName: defineMessage({
        id: '[Name]Tatiana Ntende',
        message: `Tatiana Ntende`,
      }),
      studentImage: 'Tatiana Ntende',
      grade: i18n.number(2),
      projectName: defineMessage({ message: `Learn the vowels` }),
      projectImage: 'Learn the vowels',
      projectDescription: defineMessage({
        message: `This game helps you learn about vowels.`,
      }),
      projectCategory: defineMessage({ message: `Game development` }),
      projectLink: 'https://scratch.mit.edu/projects/704538607',
    },
    {
      studentName: defineMessage({
        id: '[Name]Osho Enoch iseoluwa',
        message: `Osho Enoch iseoluwa`,
      }),
      studentImage: 'Osho Enoch iseoluwa',
      grade: i18n.number(6),
      projectName: defineMessage({ message: `DJ cat` }),
      projectImage: 'DJ cat',
      projectDescription: defineMessage({
        message: `Dance to the tunes of DJ Cat with this fun animated game.`,
      }),
      projectCategory: defineMessage({ message: `Scratch` }),
      projectLink: 'https://scratch.mit.edu/projects/710926627/',
    },
    {
      studentName: defineMessage({
        id: '[Name]Raphael Kirabo Mbonye',
        message: `Raphael Kirabo Mbonye`,
      }),
      studentImage: 'Raphael Kirabo Mbonye',
      grade: i18n.number(3),
      projectName: defineMessage({ message: `Ninja clash` }),
      projectImage: 'Ninja clash-2',
      projectDescription: defineMessage({
        // Add description
        message: ``,
      }),
      projectCategory: defineMessage({ message: `Game development` }),
      projectLink:
        'https://replit.com/@OluwadamilolaAy/M1L6Elaborate-your-registration-form-entries-1?v=1',
    },
    {
      studentName: defineMessage({
        id: '[Name]chloe Holowka',
        message: `chloe Holowka`,
      }),
      studentImage: 'chloe Holowka',
      grade: i18n.number(4),
      projectName: defineMessage({
        message: `Eligibility checker`,
      }),
      projectImage: 'Eligibility checker',
      projectDescription: defineMessage({
        message: `This app lets you check wheather you are eligible to drive or not.`,
      }),
      projectCategory: defineMessage({ message: `App development` }),
      projectLink: 'https://scratch.mit.edu/projects/723423821',
    },
    {
      studentName: defineMessage({
        id: '[Name]Gideon',
        message: `Gideon`,
      }),
      studentImage: 'Gideon',
      grade: i18n.number(5),
      projectName: defineMessage({ message: `Car game` }),
      projectImage: 'Car game-3',
      projectDescription: defineMessage({
        message: `Drive around the car on the highway. Beware of the obstacles.`,
      }),
      projectCategory: defineMessage({ message: `Scratch` }),
      projectLink:
        'https://studio.code.org/projects/applab/P-BsF15tQXazey9fTWkmLtyXbxku7j5r9QCP9p7PhEI',
    },
    {
      studentName: defineMessage({
        id: '[Name]Avram Jasi',
        message: `Avram Jasi`,
      }),
      studentImage: 'Avram Jasi',
      grade: i18n.number(5),
      projectName: defineMessage({ message: `Table generator` }),
      projectImage: 'Table generator',
      projectDescription: defineMessage({
        message: `You can generate any number table with this application.`,
      }),
      projectCategory: defineMessage({ message: `App development` }),
      projectLink:
        'https://studio.code.org/projects/playlab/TXP-6ovDU1LwaoOKsCwLYCa3VLnG30umQh7t3UW2Jj8',
    },
  ],
  NP: [
    {
      studentName: defineMessage({
        id: '[Name]Aaditya Khanal',
        message: `Aaditya Khanal`,
      }),
      studentImage: 'Aaditya Khanal',
      grade: i18n.number(12),
      projectName: defineMessage({ message: `InfoNepal` }),
      projectImage: 'InfoNepal',
      projectDescription: defineMessage({
        message: `This website gives you a detailed information about Nepal.`,
      }),
      projectCategory: defineMessage({ message: `Website development` }),
      projectLink: 'https://replit.com/join/zjibbokfwe-aadityakhanal',
    },
    {
      studentName: defineMessage({
        id: '[Name]Esha Shrestha',
        message: `Esha Shrestha`,
      }),
      studentImage: 'Esha Shrestha',
      grade: i18n.number(8),
      projectName: defineMessage({ message: `Jump off the balls` }),
      projectImage: 'Jump off the balls',
      projectDescription: defineMessage({
        message: `Make the Rabbit jump off the balls. If you touch the ball, the game gets over.`,
      }),
      projectCategory: defineMessage({ message: `Scratch` }),
      projectLink: 'https://scratch.mit.edu/projects/636004345',
    },
    {
      studentName: defineMessage({
        id: '[Name]Riyansh Sah',
        message: `Riyansh Sah`,
      }),
      studentImage: 'Riyansh Sah',
      grade: i18n.number(2),
      projectName: defineMessage({ message: `An Apple for Healthy Life` }),
      projectImage: 'An Apple for Healthy Life',
      projectDescription: defineMessage({
        message: `Learn the importance of eating apple with this fun animated project.`,
      }),
      projectCategory: defineMessage({ message: `Animation` }),
      projectLink:
        'https://studio.code.org/projects/spritelab/cjkSTaRpOv3RjtNI5VO55amGEDeRr3Kvk8YZWIwpDvA',
    },
    {
      studentName: defineMessage({
        id: '[Name]Abhyudaya Adhikari',
        message: `Abhyudaya Adhikari`,
      }),
      studentImage: 'Abhyudaya Adhikari',
      grade: i18n.number(6),
      projectName: defineMessage({ message: `Catch the falling stars` }),
      projectImage: 'Catch the falling stars-3',
      projectDescription: defineMessage({
        message: `Catch the falling stars into the basket to get points.`,
      }),
      projectCategory: defineMessage({ message: `Scratch` }),
      projectLink: 'https://scratch.mit.edu/projects/646478476',
    },
    {
      studentName: defineMessage({
        id: '[Name]Aaditya Khanal',
        message: `Aaditya Khanal`,
      }),
      studentImage: 'Aaditya Khanal',
      grade: i18n.number(12),
      projectName: defineMessage({ message: `Bank app` }),
      projectImage: 'Bank app',
      projectDescription: defineMessage({
        message: `Check your bank balance with this Bank app.`,
      }),
      projectCategory: defineMessage({ message: `Website development` }),
      projectLink: 'https://replit.com/@AadityaKhanal/BankApp?v=1',
    },
    {
      studentName: defineMessage({
        id: '[Name]Saugat Thapa',
        message: `Saugat Thapa`,
      }),
      studentImage: 'Saugat Thapa',
      grade: i18n.number(11),
      projectName: defineMessage({ message: `Exam eligibility ` }),
      projectImage: 'Exam eligibility',
      projectDescription: defineMessage({
        message: `This program lets you check wheather you are eligible to appear in a exam or not.`,
      }),
      projectCategory: defineMessage({ message: `Python` }),
      projectLink: 'https://replit.com/@justsaugat/Exam-eligibility#main.py',
    },
  ],
}
